/* InscriptionWeb */

  .close-btn {
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 1.5rem;
    background: none;
    border: none;
  }




  /* Notes */
  .btn-agregar{
    border: 0;
    height: 50px;
    background-color: #000;
    color: greenyellow;
    width: 15%;
    margin-left: 20px;
    }

    .btn-cancelar{
        background-color: #fff;
        height: 50px;
        width: 15%;
        margin-left: 20px
    }

    .note-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        background-color: rgb(0,0,0,0.1)
    }

    .close-btn {
        position: absolute;
        right: 15px;
        top: 5px;
        font-size: 1.5rem;
        background: none;
        border: none;
    }

    .note-container {
        border-bottom: 1px solid #eee;
        padding: 10px 0;
    }