.custom-height {
  height: 500px;
}

.offset-top {
  margin-top: -100px;
}

h5.offset-top {
  margin-top: -160px;
}

.video-container {
  position: absolute;
  overflow: hidden;
  height: 80vh;
  width: 100%;
  z-index: 0;
}

.video-container video {
  max-height: 80vh;
  object-fit: cover;
}

.video-overlay {
  height: 60vh;
  z-index: 1;
}


.video-container video {
  /* position: absolute; */
  top: 0;
  left: 0;
  object-fit: cover; /* Añadir esta línea */
}

.btn-whatsapp {
  position: fixed;
  height: 50px;
  width: 50px;
  font-size: 24px;
  border-radius: 100px;
  bottom: 15px;
  left: 15px;
  z-index: 1100;
}

.class-type-home {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-top: 0px;
}

.class-type-title {
  min-height: 100px;
}

.btn-whatsapp-text {
  font-size: 18px;
  top: -10px;
}

#field-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.mw-1000 {
  max-width: 1000px;
}

.hover-grow {
  transition: all 0.25s;
}

.hover-grow:hover {
  transform: scale(1.05);
}

.btn-container {
  width: 103.2% !important;
}

.bg-accent {
  background-color: var(--accent) !important;
  color: white !important;
  border-color: var(--accent);
}

.bg-accent-secondary {
  background-color: var(--accent-secondary);
}

.bg-spinning {
  background-image: url("https://i.blogs.es/a82e3b/istock-1134717663-1-/450_1000.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-opacity {
  background-color: var(--dark-transparency) !important;
}

.btn-accent {
  background-color: var(--accent);
  border-color: var(--accent);
  transition: all 0.25s;
  text-transform: uppercase;
  font-weight: 800;
  color: white;
}

.btn-accent:hover {
  background-color: var(--accent-secondary);
  border-color: var(--dark);
  color: var(--dark);
}

.bg-black {
  background-color: #000;
}

.tbm-card {
  background-color: #0a121f;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.panel-mobile-menu {
  display: none;
}

.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.page-content {
  min-height: 93vh;
}

.schedule-img {
  margin-top: 32px;
  max-width: 400px;
}

.vh-100 {
  height: 100vh;
}

.capitalize {
  text-transform: capitalize;
}

.paquete-thumbnail {
  max-height: 300px;
  object-fit: cover;
  height: 100%;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  border: 2px solid var(--sand);
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.top-arrow {
  height: 25px;
}

.top-arrow:before {
  content: "";
  position: absolute;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-bottom: 25px solid #f0f0f0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}

.schedule-class {
  border-radius: 10px;
  width: 200px;
  text-align: center;
  border: 1px solid #000;
}

.class-container {
  height: 50px;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.package-card {
  height: 350px;
}

.schedule-class {
  cursor: pointer;
}

.schedule-class:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-container {
  width: 170%;
}

.latest-badge {
  max-width: 180px;
  position: absolute;
  top: 15px;
  width: 100%;
}

.card {
  padding: 24px;
  border-radius: 0px;
  border: 0px !important;
  transition: all 0.25s;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.no-scale:hover {
  transform: none !important;
}

.payment-method {
  max-width: 75px;
}

#paypal-button {
  max-height: 55px;
  overflow: hidden;
}

.color-legend {
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.user-card {
  position: absolute;
  width: 290px;
  right: 12px;
  transition: all 0.2s;
  position: absolute !important;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 20px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 100%;
  height: 100%;
}

.btn-round {
  border-radius: 80px;
  width: 40px;
  height: 40px;
}

.about-tbm {
  min-height: 500px;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

html {
  width: 100%;
  max-width: 100%;
}

.menu-category {
  font-size: 1rem;
  width: 100%;
}

.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.Instructor-thumbnail {
  object-fit: cover;
}

.closed {
  height: 0px;
}

body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
 
.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.nav-item {
  height: 70px;
  padding-left: 12px;
  padding-right: 12px;
  transition: all 0.25s;
  margin-top: -4px;
  cursor: pointer;
}

/* .nav-item:hover {
  background-color: #e4e4e4;
  border-bottom: 3px solid var(--accent);
} */

.active-link::before {
  content: "";
  border-left: 2px solid #0d6efd;
  height: 70%;
  top: 15%;
  left: 0;
}


/* .nav-item-active {
  background-color: #e4e4e4;
  border-bottom: 3px solid var(--accent);
} */

.navbar-cta {
  transition: all 0.25s;
  margin-top: 12px;
}

.program-meta {
  min-height: 170px;
  max-height: 190px;
  overflow: hidden;
}

.program-img {
  max-height: 500px;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

.program-video-card {
  cursor: pointer;
  height: 140px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.panel-home {
  max-width: 500px;
}

.navbar-cta:hover {
  transform: scale(1.05);
}

.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 500;
  margin-top: 16px;
}

.bg-dark {
  background-color: var(--dark) !important;
}

.text-accent {
  color: var(--accent);
}

.text-left {
  text-align: left !important;
}

.side-menu {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  width: 160px;
  background-color: #191919;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 64px;
}

.main-panel {
  background: #fff;
  padding: 32px;
}

.course-content {
  padding: 16px 32px;
}

.text-sentence {
  text-transform: none;
}

.logo-footer {
  max-width: 250px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}

.content {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.schedule-class {
  border-radius: 10px;
  width: 200px;
  text-align: center;
  border: 1px solid #000;
  float: left;
}

.schedule-classes-container {
  min-height: 300px;
  height: auto;
  max-width: 300%;
}

.profile-image {
  max-width: 300px;
}

.profile-thumbnail {
  border-radius: 100px;
  max-width: 100%;
}

.logo-thumbnail {
  width: 75px;
}

img.thumbnail {
  width: 75px;
  height: 75px;
}

.user-icon {
  padding: 20px 22px;
}

.large {
  font-size: 1.2em;
}

.round {
  border-radius: 1000px;
}

.video-thumbnail {
  min-height: 200px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}

.hover-accent:hover {
  color: white;
  background-color: var(--accent);
}

.card-video {
  height: 330px;
  margin-bottom: 32px;
  transition: all 1s;
  padding-bottom: 16px;
}

.card-video p {
  font-size: 14px;
}

.card-video h3 {
  font-size: 16px;
}

.panel-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
  font-size: 24px;
}

.filter-card {
  padding: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.latest-video-bg {
  height: 100%;
  min-height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.latest-card {
  max-height: 320px;
  margin-bottom: 32px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  border-radius: 0px;
}

.latest-card-thumbnail {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  font-size: 20px;
}

.user-menu {
  margin-left: -75px;
}

.user {
  background-color: var(--sand);
  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.separator {
  margin: auto;
  width: 100px;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}

.name-row:focus {
  background-color: var(--light);
}

.home-video {
  height: 70vh;
  padding-right: 0px;
  padding-left: 0px;
  z-index: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#video {
  height: 70vh;
}

.logo-navbar {
  max-width: 50px;
}

.vh-100 {
  height: 100vh;
}

#login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
  text-transform: uppercase;
}

.br-0 {
  border-radius: 0px !important;
}

.drawer-menu {
  z-index: 1;
  transition: all 0.25s;
}

.mobile {
  border-radius: 10px;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  transform: scale(1.1);
}

.bold {
  font-weight: 800;
}

.belt-text {
  z-index: 1;
}

.black-belt {
  background-color: var(--gray);
  height: 250px;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 30%;
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.mw-800 {
  max-width: 800px;
}

.mw-1400 {
  max-width: 1320px;
}

.bg-gray {
  background-color: var(--gray);
}

.h-auto {
  height: auto;
}

.landing-class-type {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  transition: 0.5s ease;
  backface-visibility: hidden;
  z-index: 1;
  filter: brightness(60%);
}

.landing-class-type:after {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.black-overlay {
  background-color: #000;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  opacity: 0.4;
  z-index: 0;
}

.established {
  max-width: 300px;
  display: block;
  margin: auto;
}

.franjaBody .text-container {
  padding-left: 20vw;
}

.aprende {
  max-width: 50vw;
  margin-top: -32px;
  z-index: 100;
}

.class-type-description {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.checkout-logo {
  max-width: 100px;
}

.class-type-container {
  height: 400px;
  width: 100% !important;
  overflow: hidden;
}

.br-10 {
  border-radius: 10px;
}

.landing-class-type-container {
  position: relative;
  overflow: hidden;
  height: 400px;
}
.landing-class-type-container:hover .landing-class-type {
  filter: brightness(20%);
}

.landing-class-type-container:hover .landing-class-type:after {
  opacity: 0.4;
}

.landing-class-type-container:hover .class-type-description {
  opacity: 1;
  color: #fff;
}
.landing-class-type-container:hover .class-type-logo {
  opacity: 0;
}

.class-type-logo {
  position: absolute;
  top: 200px;
  width: 50%;
  height: 50px;
  object-fit: contain;
  left: 25%;
  text-align: center;
}

.bg-vertical {
  background-image: url("https://tbmcerts.s3.us-west-1.amazonaws.com/bg-login.JPG");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.show-mobile {
  display: none;
}

.main-cta-cont {
  padding-top: 20vh;
  z-index: 10;
}

.main-cta-cont h1 {
  text-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
}

.main-cta-cont p {
  font-size: 24px;
  text-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
}

.overlay {
  background-color: #000;
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0.75;
  z-index: 1;
}

.loading-text {
  z-index: 10;
}

.loading-logo {
  max-width: 200px;
}

.bg-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 300px;
  display: block;
  margin: auto;
}

.hover-shadow:hover {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.panel-home-item {
  height: 65vh;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
}

td {
  min-width: 125px;
}

.table> :not(caption)>*>* {
  padding: 10px 12px;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom: inherit;
}

.online-bg {
  background-image: url("/src/assets/images/bg-tbm-online.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}

.main-cta-btn {
  font-size: 20px;
  padding: 10px 16px;
}

.presencial-bg {
  background-image: url("/src/assets/images/bg-tbm-presencial.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}

.schedule-class-cont {
  padding: 15px;
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.25;
  z-index: 0;
}

.accordion-item, .accordion-collapse {
  transition: all 0.5s !important;
}

.video-testimonial {
  max-height: 70vh;
  transition: 1s;
  height: 100%;
  width: auto;
}

#loading {
  height: 100vh;
  z-index: 0;
  position: absolute;
  background-image: url("https://tbmcerts.s3.us-west-1.amazonaws.com/loading.JPG");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  left: 0;
}

.object-fit-cover {
  object-fit: cover;
}

.text-uppercase * {
  text-transform: uppercase;
}

.about-text {
  position: absolute;
  left: 35%;
  top: -95%;
}

#about-video {
  max-width: 1400px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 200px;
}

#about-video video {
  width: 100%;
  z-index: 1;
  max-width: 900px;
  max-height: 400px;
  object-fit: cover;
  align-items: center;
  justify-content: center;
}

#about-video .card {
  position: absolute;
  width: 140%;
  top: 10%;
  left: -50%;
  z-index: 0;
}

#about-video .card-body {
  padding-left: 40% !important;
}

#rutinas {
  min-height: 70vh;
}

#panel-home {
  display: flex;
  min-height: 70vh;
  align-items: center;
}

#panel-navbar {
  z-index: 100;
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
}

@media (max-width: 1000px) {
  #desktop-features {
    margin-top: 140px
  }
  #about-video {
      min-height: 70vh;
  }
    .aprende {
        max-width: 75%;
      }
  .franjaBody {
    height: auto !important;
    background-color: var(--dark);
  }
        .franjaBody .text-container {
          padding-left: 2rem;
          text-align: center;
        }
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
  .panel-mobile-menu {
    display: block;
  }
  #panel-navbar {
    display: none;
  }
}

@media (min-width: 767px) {
  .resp-calendar {
    display: none;
  }
}

@media (max-width: 767px) {

.quiz-img {
    max-height: 300px;
  }
    .aprende {
        max-width: 100%;
      }
  #about-video {
    margin-bottom: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #about-video .card {
    position: relative;
    top: 0%;
    left: 0;
    width: 100%;
    z-index: 0;
  }
  #about-video .card-body {
    padding-left: 0px !important;
  }
  .established {
    max-width: 200px;
  }
  .video-overlay, .video-container {
    height: 80vh;
    z-index: 1;
  }
  .video-container video {
    max-height: 80vh;
  }
  .program-video-card {
    min-height: 175px
  }
  .latest-video-bg {
    min-height: 180px;
  }
  .latest-card {
    max-height: 400px;
  }
  .schedule-img {
    max-width: 100%;
  }
  .schedule-day {
    margin-bottom: 0px !important;
    width: 100%;
  }
  .schedule-class {
    width: 50%;
  }
  .mobile-left {
    text-align: left;
  }
  .btn-reservaciones {
    padding-left: 0px;
  }
  .schedule {
    margin-top: 12px;
  }
  .schedule-class-cont {
    border: 0px !important;
    box-shadow: none !important;
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
  }
  .main-cta-btn {
    font-size: 16px;
    padding: 8px 12px;
  }
  .main-cta-cont p {
    font-size: 18px;
  }
  .show-mobile {
    display: inline;
  }
  .hide-mobile {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .main-panel {
    padding-left: 12px;
    padding-right: 12px;
  }
  .text-funny {
    font-size: 32px;
  }
  .user-card {
    display: none;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
    text-transform: capitalize !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .schedule-container {
    width: 100%;
  }
  .h-80 {
    height: auto;
  }
  .mobile-center  {
    text-align: center !important;
  }
  #main-cta {
    position: relative;
    top: auto;
    left: auto;
  }
  #video {
    margin-top: -32px;
    background-image: url("/src/assets/images/banner_movil.jpeg");
  }
}


@media (min-width: 576px) {
  .modal-dialog {
    max-width: 90% !important;
  }
}