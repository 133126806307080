.progress-bar {
    height: 25px;
    border-radius: 90px;
}

.bg-bar {
    width: 100%;
    border: 1px solid #333;
    background-color: rgb(0, 0, 0, 0.1);
}

.fill-bar {
    margin-top: -25px;
    border: 1px solid #000;
    background: var(--accent);
}