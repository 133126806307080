.border, .btn:not(.btn-link) {
    /* border: 1px solid #333 !important; */
    border-collapse: collapse;
}

.accordion-item {
    margin: 8px 0px !important;
}

.content .home-course {
    height: 70vh;
}

.home-course {
    cursor: pointer;
    min-height: 300px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.home-course:hover {
    background-color: white;
    border: 2px solid var(--accent) !important;
}

.home-course h2 {
    z-index: 1;
    font-size: 80px;
}

.btn {
    font-weight: 700;
    padding: 0.75rem 2.5rem;
    text-transform: uppercase;
}

.arrows-container {
    max-width: 150px;
    margin-left: auto;
    margin-right: 0px;
}

.arrows-container .btn.btn-sm {
    width: 50px !important;
    height: 50px !important;
    padding: 12px !important;
    font-size: 14px;
    border-radius: 100px;
}

.arrows-container .small {
    font-size: 10px;
}

.icon {
    height: 32px;
    width: 32px;
    object-fit: contain;
}

.btn-arrow {
    width: 50px;
    padding: 0px;
    height: 50px;
    border-radius: 50%;
}

.btn-notes {
    width: 75px;
    height: 75px;
    border-radius: 150px;
    padding: 12px;
    border: 4px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    right: 20px;
    top: 20px;
}

.check-complete {
    position: absolute;
    right: 0px;
    padding: 1rem;
    top: 0px;
}

.line-contact-img {
    position: absolute;
    top: -250px;
    left: -100px;
    width: 600px;
}

#course-help {
    height: 500px;
}

#features {
    margin-bottom: 80px;
}

#features img {
    width: 100%;
    object-fit: cover;
}

#features p {
    max-width: 75%;
    min-width: 280px;
}

#courses .row,
#courses .col-12,
#courses .card-container .card-inner {
    height: 45vh !important;
}

.card-container, .card-back {
    display: flex;
    align-items: center;
}

#courses-grid {
    margin-top: -40px
}

@media(max-width: 1200px) {
    #course-help {
        height: auto;
    }
    .line-contact-img {
        position: relative;
        top: auto;
        left: auto;
        margin: auto;
        display: block;
        max-width: 500px;
        width: 100%;
    }
    #courses .row,
    #courses .col-12,
    #courses .card-container .card-inner {
        height: 50vh;
    }
        .home-course h2 {
            z-index: 1;
            font-size: 64px;
        }
}

@media (max-width: 767px) {
    #features {
        margin-bottom: 32px;
    }
    #courses .card-container .card-inner {
        height: 50vh;
    }
    .card-container:not(.translated):hover .card-inner {
        transform: none;
        z-index: none;
    }
    #courses .row,
    #courses .col-12 {
        height: auto !important;
    }
    .home-course, .home-course .row {
        min-height: 45vh;
      
    }
    .home-course h2 {
        font-size: calc(2.5em + 4.5vw);
    }
    .card-container {
        height: 50vh;
    }
    .card-container.translated .card-inner {
        transform: rotateY(180deg);
        z-index: 99999 !important;
    }
    #courses-grid {
        margin-top: -40px
    }
    #courses .btn-round {
        width: 50px;
        height: 50px;
        margin: auto;
        z-index: 999;
        border-radius: 100px;
        padding: 5px 7px !important;
    }
}


@media (max-width: 480px) {
    .card-container {
        height: 60vh;
    }
}
