.fondoQuiz{
    background-color: #d1b6e4;
}

.check{
    width: 25px;
    height: 25px;
}

.btn-quiz{
    background-color: #000;
    color: greenyellow;
    height: 50px;
}


/* final examn */

.btn-choose{
    background-color: gray;
    color: white;
    height: 50px;
}