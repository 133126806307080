.sculpt{
    margin-top: 5%;
    margin-bottom: 4vw !important ;
}

.sectionContact{
    width: 50%;
}

.franjaNosotros{
    margin-top: 10vh;
    height: 100%;
}

@media screen and (max-width: 1400px) {

    .sculpt{
        margin-top: 9%;
    }
}

@media screen and (max-width: 1600px) {

    .sculpt{
        margin-top: 8%;
    }
}

@media screen and (max-width: 991px) {

    .sculpt{
        margin-top: 23vw !important;
    }
    .sectionContact{
        width: 80%;
    }
}

  @media screen and (max-width: 768px) {

}


@media screen and (max-width: 576px) {

}


  