@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
.custom-height {
  height: 500px;
}

.offset-top {
  margin-top: -100px;
}

h5.offset-top {
  margin-top: -160px;
}

.video-container {
  position: absolute;
  overflow: hidden;
  height: 80vh;
  width: 100%;
  z-index: 0;
}

.video-container video {
  max-height: 80vh;
  object-fit: cover;
}

.video-overlay {
  height: 60vh;
  z-index: 1;
}


.video-container video {
  /* position: absolute; */
  top: 0;
  left: 0;
  object-fit: cover; /* Añadir esta línea */
}

.btn-whatsapp {
  position: fixed;
  height: 50px;
  width: 50px;
  font-size: 24px;
  border-radius: 100px;
  bottom: 15px;
  left: 15px;
  z-index: 1100;
}

.class-type-home {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-top: 0px;
}

.class-type-title {
  min-height: 100px;
}

.btn-whatsapp-text {
  font-size: 18px;
  top: -10px;
}

#field-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.mw-1000 {
  max-width: 1000px;
}

.hover-grow {
  transition: all 0.25s;
}

.hover-grow:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.btn-container {
  width: 103.2% !important;
}

.bg-accent {
  background-color: var(--accent) !important;
  color: white !important;
  border-color: var(--accent);
}

.bg-accent-secondary {
  background-color: var(--accent-secondary);
}

.bg-spinning {
  background-image: url("https://i.blogs.es/a82e3b/istock-1134717663-1-/450_1000.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-opacity {
  background-color: var(--dark-transparency) !important;
}

.btn-accent {
  background-color: var(--accent);
  border-color: var(--accent);
  transition: all 0.25s;
  text-transform: uppercase;
  font-weight: 800;
  color: white;
}

.btn-accent:hover {
  background-color: var(--accent-secondary);
  border-color: var(--dark);
  color: var(--dark);
}

.bg-black {
  background-color: #000;
}

.tbm-card {
  background-color: #0a121f;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.panel-mobile-menu {
  display: none;
}

.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.page-content {
  min-height: 93vh;
}

.schedule-img {
  margin-top: 32px;
  max-width: 400px;
}

.vh-100 {
  height: 100vh;
}

.capitalize {
  text-transform: capitalize;
}

.paquete-thumbnail {
  max-height: 300px;
  object-fit: cover;
  height: 100%;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  border: 2px solid var(--sand);
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.top-arrow {
  height: 25px;
}

.top-arrow:before {
  content: "";
  position: absolute;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-bottom: 25px solid #f0f0f0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}

.schedule-class {
  border-radius: 10px;
  width: 200px;
  text-align: center;
  border: 1px solid #000;
}

.class-container {
  height: 50px;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.package-card {
  height: 350px;
}

.schedule-class {
  cursor: pointer;
}

.schedule-class:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-container {
  width: 170%;
}

.latest-badge {
  max-width: 180px;
  position: absolute;
  top: 15px;
  width: 100%;
}

.card {
  padding: 24px;
  border-radius: 0px;
  border: 0px !important;
  transition: all 0.25s;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.no-scale:hover {
  -webkit-transform: none !important;
          transform: none !important;
}

.payment-method {
  max-width: 75px;
}

#paypal-button {
  max-height: 55px;
  overflow: hidden;
}

.color-legend {
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.user-card {
  position: absolute;
  width: 290px;
  right: 12px;
  transition: all 0.2s;
  position: absolute !important;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 20px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 100%;
  height: 100%;
}

.btn-round {
  border-radius: 80px;
  width: 40px;
  height: 40px;
}

.about-tbm {
  min-height: 500px;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

html {
  width: 100%;
  max-width: 100%;
}

.menu-category {
  font-size: 1rem;
  width: 100%;
}

.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.Instructor-thumbnail {
  object-fit: cover;
}

.closed {
  height: 0px;
}

body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
 
.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.nav-item {
  height: 70px;
  padding-left: 12px;
  padding-right: 12px;
  transition: all 0.25s;
  margin-top: -4px;
  cursor: pointer;
}

/* .nav-item:hover {
  background-color: #e4e4e4;
  border-bottom: 3px solid var(--accent);
} */

.active-link::before {
  content: "";
  border-left: 2px solid #0d6efd;
  height: 70%;
  top: 15%;
  left: 0;
}


/* .nav-item-active {
  background-color: #e4e4e4;
  border-bottom: 3px solid var(--accent);
} */

.navbar-cta {
  transition: all 0.25s;
  margin-top: 12px;
}

.program-meta {
  min-height: 170px;
  max-height: 190px;
  overflow: hidden;
}

.program-img {
  max-height: 500px;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

.program-video-card {
  cursor: pointer;
  height: 140px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.panel-home {
  max-width: 500px;
}

.navbar-cta:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 500;
  margin-top: 16px;
}

.bg-dark {
  background-color: var(--dark) !important;
}

.text-accent {
  color: var(--accent);
}

.text-left {
  text-align: left !important;
}

.side-menu {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  width: 160px;
  background-color: #191919;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 64px;
}

.main-panel {
  background: #fff;
  padding: 32px;
}

.course-content {
  padding: 16px 32px;
}

.text-sentence {
  text-transform: none;
}

.logo-footer {
  max-width: 250px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}

.content {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.schedule-class {
  border-radius: 10px;
  width: 200px;
  text-align: center;
  border: 1px solid #000;
  float: left;
}

.schedule-classes-container {
  min-height: 300px;
  height: auto;
  max-width: 300%;
}

.profile-image {
  max-width: 300px;
}

.profile-thumbnail {
  border-radius: 100px;
  max-width: 100%;
}

.logo-thumbnail {
  width: 75px;
}

img.thumbnail {
  width: 75px;
  height: 75px;
}

.user-icon {
  padding: 20px 22px;
}

.large {
  font-size: 1.2em;
}

.round {
  border-radius: 1000px;
}

.video-thumbnail {
  min-height: 200px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}

.hover-accent:hover {
  color: white;
  background-color: var(--accent);
}

.card-video {
  height: 330px;
  margin-bottom: 32px;
  transition: all 1s;
  padding-bottom: 16px;
}

.card-video p {
  font-size: 14px;
}

.card-video h3 {
  font-size: 16px;
}

.panel-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
  font-size: 24px;
}

.filter-card {
  padding: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.latest-video-bg {
  height: 100%;
  min-height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.latest-card {
  max-height: 320px;
  margin-bottom: 32px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  border-radius: 0px;
}

.latest-card-thumbnail {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  font-size: 20px;
}

.user-menu {
  margin-left: -75px;
}

.user {
  background-color: var(--sand);
  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.separator {
  margin: auto;
  width: 100px;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}

.name-row:focus {
  background-color: var(--light);
}

.home-video {
  height: 70vh;
  padding-right: 0px;
  padding-left: 0px;
  z-index: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#video {
  height: 70vh;
}

.logo-navbar {
  max-width: 50px;
}

.vh-100 {
  height: 100vh;
}

#login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
  text-transform: uppercase;
}

.br-0 {
  border-radius: 0px !important;
}

.drawer-menu {
  z-index: 1;
  transition: all 0.25s;
}

.mobile {
  border-radius: 10px;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.bold {
  font-weight: 800;
}

.belt-text {
  z-index: 1;
}

.black-belt {
  background-color: var(--gray);
  height: 250px;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 30%;
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.mw-800 {
  max-width: 800px;
}

.mw-1400 {
  max-width: 1320px;
}

.bg-gray {
  background-color: var(--gray);
}

.h-auto {
  height: auto;
}

.landing-class-type {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 1;
  -webkit-filter: brightness(60%);
          filter: brightness(60%);
}

.landing-class-type:after {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.black-overlay {
  background-color: #000;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  opacity: 0.4;
  z-index: 0;
}

.established {
  max-width: 300px;
  display: block;
  margin: auto;
}

.franjaBody .text-container {
  padding-left: 20vw;
}

.aprende {
  max-width: 50vw;
  margin-top: -32px;
  z-index: 100;
}

.class-type-description {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.checkout-logo {
  max-width: 100px;
}

.class-type-container {
  height: 400px;
  width: 100% !important;
  overflow: hidden;
}

.br-10 {
  border-radius: 10px;
}

.landing-class-type-container {
  position: relative;
  overflow: hidden;
  height: 400px;
}
.landing-class-type-container:hover .landing-class-type {
  -webkit-filter: brightness(20%);
          filter: brightness(20%);
}

.landing-class-type-container:hover .landing-class-type:after {
  opacity: 0.4;
}

.landing-class-type-container:hover .class-type-description {
  opacity: 1;
  color: #fff;
}
.landing-class-type-container:hover .class-type-logo {
  opacity: 0;
}

.class-type-logo {
  position: absolute;
  top: 200px;
  width: 50%;
  height: 50px;
  object-fit: contain;
  left: 25%;
  text-align: center;
}

.bg-vertical {
  background-image: url("https://tbmcerts.s3.us-west-1.amazonaws.com/bg-login.JPG");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.show-mobile {
  display: none;
}

.main-cta-cont {
  padding-top: 20vh;
  z-index: 10;
}

.main-cta-cont h1 {
  text-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
}

.main-cta-cont p {
  font-size: 24px;
  text-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
}

.overlay {
  background-color: #000;
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0.75;
  z-index: 1;
}

.loading-text {
  z-index: 10;
}

.loading-logo {
  max-width: 200px;
}

.bg-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 300px;
  display: block;
  margin: auto;
}

.hover-shadow:hover {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.panel-home-item {
  height: 65vh;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
}

td {
  min-width: 125px;
}

.table> :not(caption)>*>* {
  padding: 10px 12px;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom: inherit;
}

.online-bg {
  background-image: url(/static/media/bg-tbm-online.f4057bce.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}

.main-cta-btn {
  font-size: 20px;
  padding: 10px 16px;
}

.presencial-bg {
  background-image: url(/static/media/bg-tbm-presencial.a83150bf.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}

.schedule-class-cont {
  padding: 15px;
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.25;
  z-index: 0;
}

.accordion-item, .accordion-collapse {
  transition: all 0.5s !important;
}

.video-testimonial {
  max-height: 70vh;
  transition: 1s;
  height: 100%;
  width: auto;
}

#loading {
  height: 100vh;
  z-index: 0;
  position: absolute;
  background-image: url("https://tbmcerts.s3.us-west-1.amazonaws.com/loading.JPG");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  left: 0;
}

.object-fit-cover {
  object-fit: cover;
}

.text-uppercase * {
  text-transform: uppercase;
}

.about-text {
  position: absolute;
  left: 35%;
  top: -95%;
}

#about-video {
  max-width: 1400px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 200px;
}

#about-video video {
  width: 100%;
  z-index: 1;
  max-width: 900px;
  max-height: 400px;
  object-fit: cover;
  align-items: center;
  justify-content: center;
}

#about-video .card {
  position: absolute;
  width: 140%;
  top: 10%;
  left: -50%;
  z-index: 0;
}

#about-video .card-body {
  padding-left: 40% !important;
}

#rutinas {
  min-height: 70vh;
}

#panel-home {
  display: flex;
  min-height: 70vh;
  align-items: center;
}

#panel-navbar {
  z-index: 100;
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
}

@media (max-width: 1000px) {
  #desktop-features {
    margin-top: 140px
  }
  #about-video {
      min-height: 70vh;
  }
    .aprende {
        max-width: 75%;
      }
  .franjaBody {
    height: auto !important;
    background-color: var(--dark);
  }
        .franjaBody .text-container {
          padding-left: 2rem;
          text-align: center;
        }
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
  .panel-mobile-menu {
    display: block;
  }
  #panel-navbar {
    display: none;
  }
}

@media (min-width: 767px) {
  .resp-calendar {
    display: none;
  }
}

@media (max-width: 767px) {

.quiz-img {
    max-height: 300px;
  }
    .aprende {
        max-width: 100%;
      }
  #about-video {
    margin-bottom: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #about-video .card {
    position: relative;
    top: 0%;
    left: 0;
    width: 100%;
    z-index: 0;
  }
  #about-video .card-body {
    padding-left: 0px !important;
  }
  .established {
    max-width: 200px;
  }
  .video-overlay, .video-container {
    height: 80vh;
    z-index: 1;
  }
  .video-container video {
    max-height: 80vh;
  }
  .program-video-card {
    min-height: 175px
  }
  .latest-video-bg {
    min-height: 180px;
  }
  .latest-card {
    max-height: 400px;
  }
  .schedule-img {
    max-width: 100%;
  }
  .schedule-day {
    margin-bottom: 0px !important;
    width: 100%;
  }
  .schedule-class {
    width: 50%;
  }
  .mobile-left {
    text-align: left;
  }
  .btn-reservaciones {
    padding-left: 0px;
  }
  .schedule {
    margin-top: 12px;
  }
  .schedule-class-cont {
    border: 0px !important;
    box-shadow: none !important;
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
  }
  .main-cta-btn {
    font-size: 16px;
    padding: 8px 12px;
  }
  .main-cta-cont p {
    font-size: 18px;
  }
  .show-mobile {
    display: inline;
  }
  .hide-mobile {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .main-panel {
    padding-left: 12px;
    padding-right: 12px;
  }
  .text-funny {
    font-size: 32px;
  }
  .user-card {
    display: none;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
    text-transform: capitalize !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .schedule-container {
    width: 100%;
  }
  .h-80 {
    height: auto;
  }
  .mobile-center  {
    text-align: center !important;
  }
  #main-cta {
    position: relative;
    top: auto;
    left: auto;
  }
  #video {
    margin-top: -32px;
    background-image: url(/static/media/banner_movil.2b0cdd57.jpeg);
  }
}


@media (min-width: 576px) {
  .modal-dialog {
    max-width: 90% !important;
  }
}
.border, .btn:not(.btn-link) {
    /* border: 1px solid #333 !important; */
    border-collapse: collapse;
}

.accordion-item {
    margin: 8px 0px !important;
}

.content .home-course {
    height: 70vh;
}

.home-course {
    cursor: pointer;
    min-height: 300px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.home-course:hover {
    background-color: white;
    border: 2px solid var(--accent) !important;
}

.home-course h2 {
    z-index: 1;
    font-size: 80px;
}

.btn {
    font-weight: 700;
    padding: 0.75rem 2.5rem;
    text-transform: uppercase;
}

.arrows-container {
    max-width: 150px;
    margin-left: auto;
    margin-right: 0px;
}

.arrows-container .btn.btn-sm {
    width: 50px !important;
    height: 50px !important;
    padding: 12px !important;
    font-size: 14px;
    border-radius: 100px;
}

.arrows-container .small {
    font-size: 10px;
}

.icon {
    height: 32px;
    width: 32px;
    object-fit: contain;
}

.btn-arrow {
    width: 50px;
    padding: 0px;
    height: 50px;
    border-radius: 50%;
}

.btn-notes {
    width: 75px;
    height: 75px;
    border-radius: 150px;
    padding: 12px;
    border: 4px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    right: 20px;
    top: 20px;
}

.check-complete {
    position: absolute;
    right: 0px;
    padding: 1rem;
    top: 0px;
}

.line-contact-img {
    position: absolute;
    top: -250px;
    left: -100px;
    width: 600px;
}

#course-help {
    height: 500px;
}

#features {
    margin-bottom: 80px;
}

#features img {
    width: 100%;
    object-fit: cover;
}

#features p {
    max-width: 75%;
    min-width: 280px;
}

#courses .row,
#courses .col-12,
#courses .card-container .card-inner {
    height: 45vh !important;
}

.card-container, .card-back {
    display: flex;
    align-items: center;
}

#courses-grid {
    margin-top: -40px
}

@media(max-width: 1200px) {
    #course-help {
        height: auto;
    }
    .line-contact-img {
        position: relative;
        top: auto;
        left: auto;
        margin: auto;
        display: block;
        max-width: 500px;
        width: 100%;
    }
    #courses .row,
    #courses .col-12,
    #courses .card-container .card-inner {
        height: 50vh;
    }
        .home-course h2 {
            z-index: 1;
            font-size: 64px;
        }
}

@media (max-width: 767px) {
    #features {
        margin-bottom: 32px;
    }
    #courses .card-container .card-inner {
        height: 50vh;
    }
    .card-container:not(.translated):hover .card-inner {
        -webkit-transform: none;
                transform: none;
        z-index: none;
    }
    #courses .row,
    #courses .col-12 {
        height: auto !important;
    }
    .home-course, .home-course .row {
        min-height: 45vh;
      
    }
    .home-course h2 {
        font-size: calc(2.5em + 4.5vw);
    }
    .card-container {
        height: 50vh;
    }
    .card-container.translated .card-inner {
        -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
        z-index: 99999 !important;
    }
    #courses-grid {
        margin-top: -40px
    }
    #courses .btn-round {
        width: 50px;
        height: 50px;
        margin: auto;
        z-index: 999;
        border-radius: 100px;
        padding: 5px 7px !important;
    }
}


@media (max-width: 480px) {
    .card-container {
        height: 60vh;
    }
}

:root {
    --blue: #3e80a4;
    --dark: #27272b;
    --primary: #C9E881;
    --accent: #D1B6E4;
    --success: #8FC040;
}

.border-primary {
    border: 2px solid #C9E881 !important;
    border: 2px solid var(--primary) !important;
}

.navbar-dark .navbar-nav .nav-link {
    color: white !important;
}

.navbar-dark .navbar-nav .nav-item:hover, .navbar-dark .navbar-nav .nav-item:hover *:not(.dropdown-item) {
    color: #C9E881 !important;
    color: var(--primary) !important;
}

.navbar-dark .navbar-nav .nav-item:hover {
    border-bottom: 4px solid #C9E881;
    border-bottom: 4px solid var(--primary);
}

.hover-blue:hover {
    cursor: pointer;
    color: white !important;
    background-color: #3e80a4 !important;
    background-color: var(--blue) !important;
}

.text-accent {
    color: #D1B6E4 !important;
    color: var(--accent) !important;
}

.text-primary {
    color: #C9E881 !important;
    color: var(--primary) !important;
}

.bg-blue {
    background-color: #3e80a4 !important;
    background-color: var(--blue) !important;
}

.bg-primary {
    background-color: #C9E881 !important;
    background-color: var(--primary) !important;
}

.btn-outline-primary {
    border-color: #C9E881 !important;
    border-color: var(--primary) !important;
    color: #C9E881 !important;
    color: var(--primary) !important;
}

.btn-primary {
    background-color: #C9E881;
    background-color: var(--primary);
    border-color: #C9E881;
    border-color: var(--primary);
    text-transform: uppercase;
    font-weight: 800;
    color: #27272b;
    color: var(--dark);
}
.btn-primary:disabled {
    background-color: #C9E881;
    background-color: var(--primary);
    border-color: #C9E881;
    border-color: var(--primary);
    text-transform: uppercase;
    font-weight: 800;
    color: #27272b;
    color: var(--dark);
}

.btn-accent {
    background-color: #D1B6E4;
    background-color: var(--accent);
    text-transform: uppercase;
    color: white;
}

.btn-dark:hover {
    color: #C9E881;
    color: var(--primary)
}

.btn-accent:hover {
    color: #D1B6E4;
    color: var(--accent);
    background-color: #27272b;
    background-color: var(--dark);
}

.btn-primary:focus, .btn-primary:hover, .btn-primary:active, .btn-primary:focus-within {
    background-color: #27272b;
    background-color: var(--dark);
    color: #C9E881;
    color: var(--primary);
    box-shadow: none !important;
    border: none !important;
}

.btn-outline-accent {
    border: 2px solid #D1B6E4 !important;
    border: 2px solid var(--accent) !important;
    color: #D1B6E4;
    color: var(--accent);
}

.btn-outline-accent:hover, .btn-outline-accent:focus, .btn-outline-accent:active {
    background-color: #D1B6E4;
    background-color: var(--accent);
    color: white;
}

.btn-outline-blue {
    border: 2px solid #3e80a4 !important;
    border: 2px solid var(--blue) !important;
    color: #3e80a4;
    color: var(--blue);
}

.text-blue {
    color: #3e80a4;
    color: var(--blue);
}

.check-complete {
    background-color: #8FC040;
    background-color: var(--success);
}

.media-lesson:hover {
    border: 2px solid #C9E881;
    border: 2px solid var(--primary);
}

.accordion-button {
    border-left-width: 5px !important;
    border-left-style: solid !important;
    border-left-color: white !important;
    border-bottom: none;
    font-size: 1.2rem;
    line-height: 1.5em;
}

.accordion-button:not(.collapsed), .accordion-button:not(.collapsed) * {
    border-left-color: #D1B6E4 !important;
    border-left-color: var(--accent) !important;
    background-color: transparent;
    color: #D1B6E4 !important;
    color: var(--accent) !important;
    font-weight: bold;
    box-shadow: none;
    
}

.accordion-button:focus, .accordion-button:active, .accordion-button:focus-within {
    box-shadow: none;
}

.accordion-body {
    border-left: 5px solid #D1B6E4;
    border-left: 5px solid var(--accent);
}
@font-face {
    font-family: "Francois One";
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/FrancoisOne-Regular.woff") format("woff")
}

@font-face {
    font-family: "Futura";
    font-weight: 400;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-light.woff") format("woff")
}

@font-face {
    font-family: "Futura";
    font-weight: 800;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura.woff") format("woff")
}

@font-face {
    font-family: "Futura Condensed Extra Bold";
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-condensed-extra-bold.woff") format("woff")
}


body, span, li, div, p, .font-body, .accordion-button {
    font-family: "Poppins", sans-serif !important;
}

.font-open-sans {
    font-family: "Open Sans", sans-serif !important;
}

.font-style-italic {
    font-style: italic !important;
}

.futura {
    font-family: "Futura", sans-serif !important;
}

.francois {
    font-family: "Francois One", sans-serif !important;
}

h1, h2, .font-header {
    font-family: "Francois One", sans-serif !important;
    font-weight: bold;
    line-height: 1.8em;
}

h3, h4, h5, h6, .font-header-secondary {
    font-family: "Futura Condensed Extra Bold", sans-serif !important;
    text-transform: uppercase;
}
.progress-bar {
    height: 25px;
    border-radius: 90px;
}

.bg-bar {
    width: 100%;
    border: 1px solid #333;
    background-color: rgb(0, 0, 0, 0.1);
}

.fill-bar {
    margin-top: -25px;
    border: 1px solid #000;
    background: var(--accent);
}

.videoSection{
    height: 300px;
    object-fit: cover;
}

.videoExtra{
    width: 90%;
    height: 200px;
}

.widthVideosExtra{
    width: 102%;
}

@media screen and (max-width: 1400px) {

}

@media screen and (max-width: 1600px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 768px) {

    .videoSection{
        height: 250px !important;   
    }
    .videoExtra{
        height: 100px;
    }
}


@media screen and (max-width: 576px) {

}


  
/* InscriptionWeb */

  .close-btn {
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 1.5rem;
    background: none;
    border: none;
  }




  /* Notes */
  .btn-agregar{
    border: 0;
    height: 50px;
    background-color: #000;
    color: greenyellow;
    width: 15%;
    margin-left: 20px;
    }

    .btn-cancelar{
        background-color: #fff;
        height: 50px;
        width: 15%;
        margin-left: 20px
    }

    .note-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        background-color: rgb(0,0,0,0.1)
    }

    .close-btn {
        position: absolute;
        right: 15px;
        top: 5px;
        font-size: 1.5rem;
        background: none;
        border: none;
    }

    .note-container {
        border-bottom: 1px solid #eee;
        padding: 10px 0;
    }
.sculpt{
    margin-top: 5%;
    margin-bottom: 4vw !important ;
}

.sectionContact{
    width: 50%;
}

.franjaNosotros{
    margin-top: 10vh;
    height: 100%;
}

@media screen and (max-width: 1400px) {

    .sculpt{
        margin-top: 9%;
    }
}

@media screen and (max-width: 1600px) {

    .sculpt{
        margin-top: 8%;
    }
}

@media screen and (max-width: 991px) {

    .sculpt{
        margin-top: 23vw !important;
    }
    .sectionContact{
        width: 80%;
    }
}

  @media screen and (max-width: 768px) {

}


@media screen and (max-width: 576px) {

}


  
.fondoQuiz{
    background-color: #d1b6e4;
}

.check{
    width: 25px;
    height: 25px;
}

.btn-quiz{
    background-color: #000;
    color: greenyellow;
    height: 50px;
}


/* final examn */

.btn-choose{
    background-color: gray;
    color: white;
    height: 50px;
}

.cuadroBody{
    height: 30vw;
}

.cuadroBodyPadding{
    margin-left: 20vw;
}

.imgCardBody{
    z-index: 999;
    width: 48%;
}

.franjaBody{
    height: 500px;
    margin-bottom: 400px;
}

.franjaGanar{
    margin-top: 35px;
}

.franjaGanarAccess{
    margin-top: -35px;
    z-index: 9999999999;
}

.imgRutinas{
    width: 80%;
    margin-top: -2px;
    margin-left: 20%;
    object-fit: cover;
    position: absolute;
    justify-content: end;
}

.imgRutinasRoute{
    width: 100%;
    margin-left: -25%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    justify-content: start;
}

.cardRutinas{
    margin-top: 170px;
}


.card-container {
    position: relative;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    cursor: pointer;
  }
  
  .card-inner {
    position: relative;
    width: 100%;
    height: 40vh;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
  }
  
  .card-container:hover .card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    z-index: 99999 !important;
  }
  
  .card-front,
  .card-back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999 !important;

  }
  
  .card-back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    z-index: 99999 !important;
  }

  .cardCupo{
    color: #bca3cc;
    font-weight: 600;
  }

  .buttonIns{
    height: 60px !important;
  }

  .introduccion{
    margin-bottom: 0px;
    position: absolute;
    /* position: absolute; */
}


@media screen and (max-width: 1200px) {
    .cuadroBody{
        height: 100% !important;
    }
    .introduccion{
        margin-bottom: 200px;
        position: absolute;
        /* position: absolute; */
    }
}
  @media screen and (max-width: 991px) {
    .video{
        height: 100% !important;
    }
    .introduccion{
        margin-bottom: 200px;
        position: absolute;
        /* position: absolute; */
    }
    .franjaGanar{
        margin-top: 40px;
    }

    .cuadroBodyPadding{
        margin-left: 10vw;
    }
    .imgCardBody{
        z-index: 999;
        width: 40vw;
    }
    .franjaBody{
        height: 50vh;
        margin-bottom: 100px;
    }
    .imgRutinas{
        margin-left: 0%;
        height: 70%;
        widows: auto;
        object-fit: cover;
        position: absolute;
        justify-content: end;
    }
    .imgRutinasRoute{
        width: 100% !important;
        margin-top: -56px;
        margin-left: 0%;
        height: 800px;
        position: absolute;
        justify-content: start;
    }
    .cardRutinas{
        margin-top: 30vw !important;
        margin-top: 170px;

    }

  }

  @media screen and (max-width: 768px) {
    .cuadroBody{
        height: 100% !important;
    }
    .franjaGanar{
        margin-top: 60px;
    }
    .imgCardBody{
        z-index: 999;
        width: 50vw;
    }
    .introduccion{
        margin-top: -220px !important;
        margin-bottom: 200px;
        position: absolute;
        /* position: absolute; */
    }
}


@media screen and (max-width: 576px) {

    .cuadroBody{
        height: 100% !important;
    }
    .imgCardBody{
        z-index: 999;
        width: 56vw;
    }
}

@media (max-width: 480px) {
    .established {
        max-width: 100px;
    }
    .video-container, .video-overlay  {
        height: 90vh;
    }
    .video-container video {
        max-height: 90vh;
    }
}


  
