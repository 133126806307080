:root {
    --blue: #3e80a4;
    --dark: #27272b;
    --primary: #C9E881;
    --accent: #D1B6E4;
    --success: #8FC040;
}

.border-primary {
    border: 2px solid var(--primary) !important;
}

.navbar-dark .navbar-nav .nav-link {
    color: white !important;
}

.navbar-dark .navbar-nav .nav-item:hover, .navbar-dark .navbar-nav .nav-item:hover *:not(.dropdown-item) {
    color: var(--primary) !important;
}

.navbar-dark .navbar-nav .nav-item:hover {
    border-bottom: 4px solid var(--primary);
}

.hover-blue:hover {
    cursor: pointer;
    color: white !important;
    background-color: var(--blue) !important;
}

.text-accent {
    color: var(--accent) !important;
}

.text-primary {
    color: var(--primary) !important;
}

.bg-blue {
    background-color: var(--blue) !important;
}

.bg-primary {
    background-color: var(--primary) !important;
}

.btn-outline-primary {
    border-color: var(--primary) !important;
    color: var(--primary) !important;
}

.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
    text-transform: uppercase;
    font-weight: 800;
    color: var(--dark);
}
.btn-primary:disabled {
    background-color: var(--primary);
    border-color: var(--primary);
    text-transform: uppercase;
    font-weight: 800;
    color: var(--dark);
}

.btn-accent {
    background-color: var(--accent);
    text-transform: uppercase;
    color: white;
}

.btn-dark:hover {
    color: var(--primary)
}

.btn-accent:hover {
    color: var(--accent);
    background-color: var(--dark);
}

.btn-primary:focus, .btn-primary:hover, .btn-primary:active, .btn-primary:focus-within {
    background-color: var(--dark);
    color: var(--primary);
    box-shadow: none !important;
    border: none !important;
}

.btn-outline-accent {
    border: 2px solid var(--accent) !important;
    color: var(--accent);
}

.btn-outline-accent:hover, .btn-outline-accent:focus, .btn-outline-accent:active {
    background-color: var(--accent);
    color: white;
}

.btn-outline-blue {
    border: 2px solid var(--blue) !important;
    color: var(--blue);
}

.text-blue {
    color: var(--blue);
}

.check-complete {
    background-color: var(--success);
}

.media-lesson:hover {
    border: 2px solid var(--primary);
}

.accordion-button {
    border-left-width: 5px !important;
    border-left-style: solid !important;
    border-left-color: white !important;
    border-bottom: none;
    font-size: 1.2rem;
    line-height: 1.5em;
}

.accordion-button:not(.collapsed), .accordion-button:not(.collapsed) * {
    border-left-color: var(--accent) !important;
    background-color: transparent;
    color: var(--accent) !important;
    font-weight: bold;
    box-shadow: none;
    
}

.accordion-button:focus, .accordion-button:active, .accordion-button:focus-within {
    box-shadow: none;
}

.accordion-body {
    border-left: 5px solid var(--accent);
}