
.cuadroBody{
    height: 30vw;
}

.cuadroBodyPadding{
    margin-left: 20vw;
}

.imgCardBody{
    z-index: 999;
    width: 48%;
}

.franjaBody{
    height: 500px;
    margin-bottom: 400px;
}

.franjaGanar{
    margin-top: 35px;
}

.franjaGanarAccess{
    margin-top: -35px;
    z-index: 9999999999;
}

.imgRutinas{
    width: 80%;
    margin-top: -2px;
    margin-left: 20%;
    object-fit: cover;
    position: absolute;
    justify-content: end;
}

.imgRutinasRoute{
    width: 100%;
    margin-left: -25%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    justify-content: start;
}

.cardRutinas{
    margin-top: 170px;
}


.card-container {
    position: relative;
    perspective: 1000px;
    cursor: pointer;
  }
  
  .card-inner {
    position: relative;
    width: 100%;
    height: 40vh;
    transform-style: preserve-3d;
    transition: transform 0.5s;
  }
  
  .card-container:hover .card-inner {
    transform: rotateY(180deg);
    z-index: 99999 !important;
  }
  
  .card-front,
  .card-back {
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999 !important;

  }
  
  .card-back {
    transform: rotateY(180deg);
    z-index: 99999 !important;
  }

  .cardCupo{
    color: #bca3cc;
    font-weight: 600;
  }

  .buttonIns{
    height: 60px !important;
  }

  .introduccion{
    margin-bottom: 0px;
    position: absolute;
    /* position: absolute; */
}


@media screen and (max-width: 1200px) {
    .cuadroBody{
        height: 100% !important;
    }
    .introduccion{
        margin-bottom: 200px;
        position: absolute;
        /* position: absolute; */
    }
}
  @media screen and (max-width: 991px) {
    .video{
        height: 100% !important;
    }
    .introduccion{
        margin-bottom: 200px;
        position: absolute;
        /* position: absolute; */
    }
    .franjaGanar{
        margin-top: 40px;
    }

    .cuadroBodyPadding{
        margin-left: 10vw;
    }
    .imgCardBody{
        z-index: 999;
        width: 40vw;
    }
    .franjaBody{
        height: 50vh;
        margin-bottom: 100px;
    }
    .imgRutinas{
        margin-left: 0%;
        height: 70%;
        widows: auto;
        object-fit: cover;
        position: absolute;
        justify-content: end;
    }
    .imgRutinasRoute{
        width: 100% !important;
        margin-top: -56px;
        margin-left: 0%;
        height: 800px;
        position: absolute;
        justify-content: start;
    }
    .cardRutinas{
        margin-top: 30vw !important;
        margin-top: 170px;

    }

  }

  @media screen and (max-width: 768px) {
    .cuadroBody{
        height: 100% !important;
    }
    .franjaGanar{
        margin-top: 60px;
    }
    .imgCardBody{
        z-index: 999;
        width: 50vw;
    }
    .introduccion{
        margin-top: -220px !important;
        margin-bottom: 200px;
        position: absolute;
        /* position: absolute; */
    }
}


@media screen and (max-width: 576px) {

    .cuadroBody{
        height: 100% !important;
    }
    .imgCardBody{
        z-index: 999;
        width: 56vw;
    }
}

@media (max-width: 480px) {
    .established {
        max-width: 100px;
    }
    .video-container, .video-overlay  {
        height: 90vh;
    }
    .video-container video {
        max-height: 90vh;
    }
}


  