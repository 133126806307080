
.videoSection{
    height: 300px;
    object-fit: cover;
}

.videoExtra{
    width: 90%;
    height: 200px;
}

.widthVideosExtra{
    width: 102%;
}

@media screen and (max-width: 1400px) {

}

@media screen and (max-width: 1600px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 768px) {

    .videoSection{
        height: 250px !important;   
    }
    .videoExtra{
        height: 100px;
    }
}


@media screen and (max-width: 576px) {

}


  